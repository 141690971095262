import React from "react";
import SvgStyle from "../styles/SvgStyle";

const SvgSmallMan = ({ pathRef, location }) => (
  <SvgStyle location={location} className="anim svgSmallMan">
    <svg
      viewBox="0 0 1366 768"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      id="firstSVG"
    >
      <path
        id="path1"
        ref={pathRef}
        d="M583.77,782.64c-0.28-5.61,15.21-22.9,34.71-29.45
      c6.44-2.16,11.59-2.28,13.02-2.69c43.02-12.27,60.03-32.48,90-49c54.86-26.67,181.02-7.8,183-29c-1.42-3.31-3.12-8.06-4.13-13.96
      c-0.77-4.51-1.76-10.3-0.33-17.22c0.53-2.58,3.32-16.13,11.23-18.19c5.88-1.53,8.97,4.75,14.14,4.2
      c10.2-1.07,10.49-26.76,19.4-42.67c8.02-14.33,21.33-20.32,32.12-25.75c48.84-24.62,101.53,1.35,106.57,3.93
      c17.28,8.85,36.63,18.76,44.92,39.37c6.4,15.93,5.37,36.21,16.34,39.88c5.99,2.01,10.83-2.53,15.93,1.23
      c6.28,4.62,5.17,16.09,5.05,17.16c-0.43,3.93-1.48,4.58-4.44,13.48c-2.45,7.38-3.68,11.07-3.68,12.87
      c0.02,14.26,61.4,26.32,95.76,33.52c18.78,3.93,47.91,10.27,84.11,19.18c21.16,10.21,37.66,21.62,36.5,32.5
      C1366.33,844.33,585.69,821.35,583.77,782.64z"
      />
    </svg>
  </SvgStyle>
);

export default SvgSmallMan;
