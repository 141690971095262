import { createGlobalStyle } from "styled-components";
import fontCirculus from "../../assets/fonts/cirruscumulus-master/CirrusCumulus.woff";
import fontOld from "../../assets/fonts/old-round/OldRound-30-1-1.ttf";
import fontOldHover from "../../assets/fonts/old-round/OldRound-30-hover.ttf";

import fontOldNonSelect from "../../assets/fonts/old-round/OldRound-30-nonselect.ttf";

const TypographyStyles = createGlobalStyle`


  @font-face {
    font-family: "fontCirculus";
    src: url(${fontCirculus});
    font-display: swap;
  }

  @font-face {
    font-family: "fontOld";
    src: url(${fontOld});
    font-display: swap;
  }
  @font-face {
    font-family: "fontOldHover";
    src: url(${fontOldHover});
    font-display: swap;
  }
  @font-face {
    font-family: "fontOldNonSelect";
    src: url(${fontOldNonSelect});
    font-display: swap;
  }

  body {
    font-variant-ligatures: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.9vw;
  }




  


`;

export default TypographyStyles;
