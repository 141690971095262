import React from "react";
import SvgStyle from "../styles/SvgStyle";

const SvgComputer = ({ pathRef, location }) => (
  <SvgStyle location={location} className="anim svgComputer">
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1366 768"
      style={{ enableBackground: "new 0 0 1366 768" }}
      xmlSpace="preserve"
    >
      <g>
        <path
          className="st0"
          d="M1224.5,17.9l16.3-0.8l-743,36c-15,0.9-13.6,15-13.6,15s0,373.1,0,390.6c0,17.5,9.5,20.3,9.5,20.3l57.2,4.6
		v-28.7c20.4-4.8,46.3,1.9,46.3,1.9l1.4,26.7c0,0,611.7,24.6,636.2,28.3 M531.8,425.9v-325L1185,73.3l-4.1,375.1L531.8,425.9z"
        />
        <path
          className="st1"
          d="M1235.6,511.9l43.3-5.3c19.9,0,20-15.8,20-15.8s6.3-415.4,7.2-442.4c0.6-17.9-15-23-15-23l-43.7-7.2l-6.7-1.1
		l0,0"
        />
        <path
          className="st1"
          d="M1235.6,511.9l-1-0.1c-3,0.1-6.4-0.1-10.2-0.6c-24.5-3.7-626-27.7-626-27.7l-1.4-26.7c0,0-25.9-6.7-46.3-1.9
		v28.7l630.1,28.4H1235.6z"
        />
        <path
          className="st0"
          d="M1234.7,511.8l-10.2-0.6C1228.3,511.7,1231.7,511.9,1234.7,511.8z"
        />
        <path
          className="st0"
          d="M550.9,483.5v28.4l574.8,27.8h0.1l0,0l55.1,2.7v-30.5L550.9,483.5z"
        />
        <polygon
          className="st2"
          points="1180.9,511.9 1180.9,542.4 1278.9,506.6 1235.6,511.9 	"
        />
        <path
          className="st0"
          d="M1026.6,536.1c-3.8,0-180-0.2-279.9-13.6v16.2c0,0,115.4,32.8,280.1,6.1L1026.6,536.1L1026.6,536.1
		C1026.7,536.1,1026.7,536.1,1026.6,536.1z"
        />
        <polygon
          className="st1"
          points="1185,73.3 531.8,100.9 531.8,425.9 1180.9,448.4 	"
        />
        <line className="st2" x1="1240.8" y1="17" x2="1235.6" y2="511.9" />
      </g>
    </svg>
  </SvgStyle>
);

export default SvgComputer;
