import React from "react";
import SvgStyle from "../styles/SvgStyle";

const SvgBigMan = ({ pathRef, location }) => (
  <SvgStyle location={location} className="anim secondSVG svgBigMan">
    <svg
      viewBox="0 0 1366 768"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        className="st0"
        ref={pathRef}
        d="M35.8,775.7c0-3.7,2.3-16.9,9.8-28.7c8.5-13.4,17.4-15.2,32.5-31.2c0,0,7-7.5,16.8-21.7
	c22.4-32.4,29-86.7,31.6-99.3c6.4-31.3,58-66.5,190.2-95.1c9.1-2-1-14-2.6-23.2c-0.1-0.6-0.2-1.2-0.3-1.9c-0.9-5.9-2-13.4-0.4-22.3
	c0.6-3.3,3.8-20.9,12.9-23.6c6.8-2,10.3,6.2,16.3,5.4c11.7-1.4,12.1-34.7,22.3-55.4c9.2-18.6,24.6-26.4,37-33.4
	c56.2-31.9,116.9,1.8,122.7,5.1c19.9,11.5,42.2,24.3,51.7,51.1c7.4,20.7,6.2,47,18.8,51.7c6.9,2.6,12.5-3.3,18.3,1.6
	c7.2,6,6,20.9,5.8,22.3c-0.5,5.1-1.7,5.9-5.1,17.5c-2.4,8.1-4.2,15.2-4.2,16.7c-0.7,16.9,95.3,12.3,121.6,55
	c22.9,37.2,13.9,34.1,47.3,111.1c0.5,1.1,38.2,51.4,38.9,52.4c43.4,58.9,28.3,59.8-5.6,64.7C765.2,801.4,35.8,790.7,35.8,775.7z"
      />
    </svg>
  </SvgStyle>
);

export default SvgBigMan;
