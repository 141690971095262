import React from "react";
import SvgStyle from "../styles/SvgStyle";

const SvgTel = ({ pathRef, location }) => (
  <SvgStyle location={location} className="anim svgTel">
    <svg
      viewBox="0 0 1366 768"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        ref={pathRef}
        d="M736.6,364.2c-17.4-40.2,0-24.6-22-45.8s-14-25.4-25.4-37.7c-11.4-12.3-31.6-35.6-31.6-35.6c-19.4,4-1.9,33.9-2.5,39
		c0,0,6.3,14.8,9.7,16.1c3.9,3.2,12.8,23.1,14.1,34.8l-36.1-76.8l1.1-0.8l-8.5-18.2l-1.1,0.5L614,197.2c-8.4-12-19.4-6.6-19.4-6.6
		l-76.8,36.8c-9.7,7.4-5.1,18.5-5.1,18.5l11.2,22.5l-0.8,0.6l4.7,10.7l1-0.6l2.6,5.7l-0.9,0.5l5.5,11.6l16.8,35.2
		c-3,6.7-6,13.5-9,20.2c0,0-5.5,6.8-1.3,11.9c4.2,5.1,5.1,25.5,14,24.9c8.9-0.7-11,9.5,3,23.9c14,14.4,6.3,11.9,17.4,18.6
		c11,6.8,16.5,6.8,16.5,6.8s25.8,10.2,44.9,19.5S753.9,404.4,736.6,364.2z M517.9,243.3c0,0-2.5-6,3.1-11.6l11.3-5.4l1.5,2.6
		c0,0,2.4,3.9,6,2c3.6-1.9,45.4-21.4,45.4-21.4s4-2.1,0.7-8.1l11.4-5.5c0,0,5.8-2.3,11.2,3.7s85.5,181,85.5,181s4,7.8-4,12.7
		l-74.1,35c0,0-6.6,4-12.8-4.6L517.9,243.3z"
      />
    </svg>
  </SvgStyle>
);

export default SvgTel;
