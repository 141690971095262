import React from "react";
import SvgStyle from "../styles/SvgStyle";

const SvgMiddleMan = ({ pathRef, location }) => (
  <SvgStyle location={location} className="anim secondSVG svgMiddleMan">
    <svg
      viewBox="0 0 1366 768"
      id="path2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="path2"
        ref={pathRef}
        d="M583.77,766.5c-0.28-6.79,15.21-27.75,34.71-35.68
      c6.44-2.62,11.59-2.77,13.02-3.26c43.02-14.87,60.03-39.35,90-59.38c54.86-32.31,181.02-9.45,183-35.14
      c-1.42-4.01-3.12-9.76-4.13-16.92c-0.77-5.46-1.76-12.48-0.33-20.87c0.53-3.13,3.32-19.55,11.23-22.04
      c5.88-1.85,8.97,5.75,14.14,5.09c10.2-1.3,10.49-32.42,19.4-51.71c8.02-17.36,21.33-24.62,32.12-31.21
      c48.84-29.84,101.53,1.64,106.57,4.77c17.28,10.72,36.63,22.73,44.92,47.7c6.4,19.3,5.37,43.88,16.34,48.33
      c5.99,2.43,10.83-3.06,15.93,1.48c6.28,5.6,5.17,19.49,5.05,20.79c-0.43,4.76-1.48,5.55-4.44,16.33
      c-2.45,8.94-3.68,13.42-3.68,15.59c0.02,17.28,61.4,31.9,95.76,40.62c18.78,4.76,47.91,12.44,84.11,23.24
      c21.16,12.37,37.66,26.2,36.5,39.38C1366.33,841.26,585.69,813.42,583.77,766.5z"
      />
    </svg>
  </SvgStyle>
);

export default SvgMiddleMan;
