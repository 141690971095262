import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
  --black: #070024;


  }
  body {
    color: var(--black);
    scrollbar-color: #cfcfcf white;
    scrollbar-width: thin;

    overflow: hidden;
  }

  p, h1, h2, h3, h4, h5, ul {
    padding: 0;
    margin: 0;
  
    margin-bottom: 0.5rem;


  }

  h4 {
    font-family: "fontOld";
 font-size: 1rem;
    text-transform: uppercase;
  }
  a {
    color: var(--black);
    text-decoration: none;
    
  }






  @media (max-width: 900px) {
 h4 {font-size: 1.5rem; margin-bottom: 1.2rem;
}
 p, a, li {font-size: 0.8rem;
    margin-bottom: 0.8rem;}


  
 }
  


`;

export default GlobalStyles;
