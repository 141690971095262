import React from "react";
import Layout from "./src/components/Layout";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>;
};
