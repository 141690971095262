import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useTranslation } from "react-i18next";

export default function SEO({
  children,
  location,
  description,
  title,
  image,
  titlePage,
}) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const { i18n } = useTranslation();

  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetadata.title;
  const metaImage = image || "/favicon.ico";
  const pageTitle = titlePage || site.siteMetadata.title;

  return (
    <Helmet titleTemplate={`%s | ${metaTitle}`} defaultTitle={metaTitle}>
      <html lang={i18n.language} />
      {/* <title>{pageTitle}</title> */}
      {/* Fav Icons */}

      <link rel="apple-touch-icon" sizes="180x180" href="/favicon.ico" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon.ico" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon.ico" />
      <link rel="manifest" href="/manifest.webmanifest" />
      <link rel="alternate icon" href="/favicon.ico" />
      {/* Meta Tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta name="description" content={metaDescription} />

      {/* Open Graph */}
      {location && <meta property="og:url" content={location.href} />}

      <meta property="og:image" content={metaImage} />
      <meta property="og:title" content={pageTitle} key="ogtitle" />
      <meta property="og:site_name" content={metaTitle} key="ogsitename" />
      <meta property="og:description" content={metaDescription} key="ogdesc" />
      {children}
    </Helmet>
  );
}
