import React, { useState, useEffect } from "react";
import "normalize.css";

import GlobalStyles from "./styles/GlobalStyles";
import TypographyStyles from "./styles/TypographyStyles";
import SEO from "./SEO";

import Menu from "./Menu";

export default function Layout({ children }) {
  const [isMobile, setIsMobile] = useState(false); // Default to false

  useEffect(() => {
    // Function to determine if the current view is mobile
    function checkIfMobile() {
      return window.innerWidth <= 900; // Example breakpoint
    }

    // Initial check on the client side
    setIsMobile(checkIfMobile());

    function handleResize() {
      setIsMobile(checkIfMobile());
    }

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <SEO
        description={children.props.data?.seo?.nodes[0]?.description}
        image={children.props.data?.seo?.nodes[0]?.image?.asset?.fluid?.src}
        title={children.props.data?.seo?.nodes[0]?.title}
        titlePage={children.props.data?.seo?.nodes[0]?.titlePage}
      />

      <GlobalStyles />
      <TypographyStyles />

      <Menu location={children.props.location.pathname} />
      {children}
    </>
  );
}
