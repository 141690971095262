import { styled } from "styled-components";

const SvgStyle = styled.div`
  svg {
    pointer-events: none;
  }
  a {
    font-size: 3.5vw;
  }

  &&.svg-menu,
  &&.svg-projects {
    svg {
      width: 100%;
      height: auto;
      overflow: visible;
      enable-background: new 0 0 1920 1080;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -2;
      text {
        font-size: 2vw;
        font-family: "fontOldNonSelect";
        letter-spacing: 1.5px;
        fill: var(--black);
        z-index: 12;
        pointer-events: all;
        transition: all 0.5s ease-in;
        a {
          opacity: 0.4;
        }
      }
      text:hover {
        font-family: "fontOldHover";
        a {
          opacity: 1;
        }
      }

      .st0 {
        fill: transparent;
        stroke: transparent;
        stroke-width: 0px;
        pointer-events: none;
      }
      /* z-index: 11; */
    }
  }

  &&.svg-projects {
    display: ${({ location }) =>
      location.match(/^\/projects\/[^/]+\/.*$/) ? "block" : "none"};
    svg {
      scale: 1.3;
      left: 16vw;

      top: 8vw;

      text {
        opacity: 1;
        font-family: "fontOld";
        font-size: 0.9vw;
      }
      text:hover {
        opacity: 1;
        font-family: "fontOld";
        font-size: 0.9vw;
        color: var(--black);
      }
    }
  }

  &&.anim {
    svg {
      pointer-events: none;
      position: absolute;
      z-index: 12;
      top: 0;

      path {
        pointer-events: none !important;
        fill: white;
        stroke: var(--black);
        stroke-width: 0.5px;
      }
    }
  }

  &&.svgTel {
    svg {
      pointer-events: none;
      width: 122vw;
      left: -14.5vw;
      top: 17vw;
      display: ${({ location }) =>
        location === "/projects/" || location === "/projects" ? "none" : ""};
      /* z-index: -12; */
    }
  }

  &&.svgComputer {
    svg {
      .st0 {
        stroke-miterlimit: 10;
      }
      .st1 {
        fill: transparent;

        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      }
      .st2 {
        fill: white;
        stroke: var(--black);
        stroke-width: 0.5px;
      }
    }
  }

  &&.svgArrow {
    path {
      stroke: transparent;
    }

    .st0 {
      stroke: #000000;
      stroke-width: 3;
      stroke-miterlimit: 10;
    }
    .st1 {
      stroke: #000000;
      stroke-miterlimit: 10;
    }
  }

  &&.svgBigMan {
    svg {
      path {
        fill: #f1f1f1;
      }

      .st0 {
        stroke: #000000;
        stroke-miterlimit: 10;
      }
      display: none;
    }
  }

  &&.svgMiddleMan {
    svg {
      path {
        fill: #f1f1f1;
      }

      display: none;
    }
  }

  &&.svgSmallMan {
    svg {
      path {
        fill: #f1f1f1;
      }
    }
  }

  .active {
    font-family: "fontOld";
    opacity: 1 !important;
  }

  .secondSVG {
    display: none;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export default SvgStyle;
