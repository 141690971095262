import { styled } from "styled-components";

const MenuStyle = styled.div`
  .menu-mobile {
    display: flex;
    padding: 3% 7%;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    background-color: #e2e2e2;
    position: sticky;
    top: 0;
    a {
      margin-bottom: 0;
    }
    a.active {
      color: blue;
    }
  }

  // pour la version desktop le .menu-mobile est en display none
  @media (min-width: 900px) {
    .menu-mobile {
      display: none;
    }
  }
`;

export default MenuStyle;
