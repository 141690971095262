import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import SvgStyle from "../styles/SvgStyle";

function SvgComponentTrace1({ pathMenuRef, location }) {
  return (
    <SvgStyle location={location} className="svg-menu">
      <svg viewBox="0 0 1920 1080">
        <path
          className="st0"
          id="wavepath"
          d="M986.5,900.5c-16.5,29.8-19.2,28.4-68.3,28.3c-116.7-0.3-71.5-34-143.7-32.3c-45.6,1.1-125.5,48.8-184,49
        c-30.7,0.1-23.7-8.3-55-18c-37.8-11.8-100.1-23.9-124.9-46.9c-32.2-29.8-7.9-42.4-25.1-69.1c-9.3-14.5-66.6-52.4-76.6-69.5
        c-11.6-19.9-8.6-35.1-17.4-57.5c-6.7-17-26.2-40.9-29.2-58.8c-4.4-26.3,9-31.3,17.2-58.2c8-26.3,39.1-23,81-69
        c14-15.3,33.7-56,18-89c-38-80,18.6-93.6,36-121c33.9-53.3,81.4-65.5,83-88c2-28.7-24-7,6-63c25.3-47.1,79.7-82,136-84
        c13-0.5,59.3,3.2,104,33c42,28,40.5,47.8,44.4,74.8c7.6,54.2,13.1,36.8,30.6,56.2c18,19.8,42.4,35.3,69.2,45.5
        c47.2,17.9,50.8,52.5,141.8-1.5c57.7-34.2,14-69,98-112c32.3-16.5,80-38,137.7,42.8c18.6,26,70.3,29.2,95.3,68.2
        c25.7,40,59,0,101.4,77.3c23.5,42.9,89.9-19.2,107.6,10.7c19,32,52.7,64.5,71,85c38.2,42.9,41.8,126.2,43,144
        c2.1,31.5,16.2,36.3,1,94c-5.4,20.5-17.1,55.4-24,93c-8,43.8-29.1,66.8-31.7,90c-5.3,46.3-21,23-36.3,45c-28.6,41.1-110,45-118,87
        c-8.2,43-95.2,12.8-133,3c-32.3-8.4-59.1-0.5-75-22c-18.5-25-31.7-54.6-29-82c3.4-33.8,23.4-65.4-3-94c-16.4-17.8-51.5,4.7-78,0
        c-45-8-94-4-99,20C1050.3,846.3,1005.7,865.8,986.5,900.5z"
        />

        <text>
          <textPath className="my-text" href="#wavepath" startOffset="-50%">
            <animate
              attributeName="startOffset"
              from="0%"
              to="98%"
              begin="0s"
              dur="50s"
              repeatCount="indefinite"
            ></animate>

            <tspan>
              <Link
                className={
                  location === "/about/" || location === "/about"
                    ? "active"
                    : ""
                }
                to="/about"
              >
                About
              </Link>
            </tspan>

            <tspan dx="30">
              <Link className={location === "/" ? "active" : ""} to="/">
                Contact
              </Link>
            </tspan>

            <tspan dx="30">
              <Link
                className={
                  location === "/projects/" || location === "/projects"
                    ? "active"
                    : ""
                }
                to="/projects"
              >
                Projects
              </Link>
            </tspan>
          </textPath>
        </text>
      </svg>
    </SvgStyle>
  );
}

export default SvgComponentTrace1;
