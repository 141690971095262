import React, { useEffect, useLayoutEffect, useRef } from "react";
import SvgComponentTrace1 from "./svg-component/SvgComponentTrace1";
import SvgSmallMan from "./svg-component/SvgSmallMan";
import SvgMiddleMan from "./svg-component/SvgMiddleMan";
import SvgArrow from "./svg-component/SvgArrow";
import SvgTel from "./svg-component/SvgTel";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import { gsap } from "gsap";
import MenuStyle from "./styles/MenuStyle";
import SvgComputer from "./svg-component/SvgComputer";
import SvgBigMan from "./svg-component/SvgBigMan";
import SvgLineProjects from "./svg-component/SvgLineProjects";
import { Link } from "gatsby";

gsap.registerPlugin(MorphSVGPlugin);

const Menu = ({ location }) => {
  const pathSmallManRef = useRef(null);
  const pathMiddleManRef = useRef(null);
  const pathBigManRef = useRef(null);
  const pathTelRef = useRef(null);
  const pathArrowRef = useRef(null);
  const pathMenuRef = useRef(null);
  const pathComputerRef = useRef(null);
  const pathLineProjectRef = useRef(null);

  useLayoutEffect(() => {
    // First, reset any ongoing animations
    gsap.killTweensOf([
      pathSmallManRef.current,
      pathMiddleManRef.current,
      pathBigManRef.current,
    ]);

    // Determine target SVG path
    let targetPath = pathSmallManRef.current;
    if (location === "/projects" || location === "/projects/") {
      targetPath = pathBigManRef.current;
    }
    if (location === "/contact" || location === "/contact/") {
      targetPath = pathMiddleManRef.current;
    }

    // Animate the morph
    gsap.to(pathSmallManRef.current, {
      duration: 0.7,
      morphSVG: targetPath,
      ease: "power3.inOut",
      repeat: 0,
      yoyo: false,
    });

    return () => {
      // Cleanup function
      gsap.killTweensOf(pathSmallManRef.current);
    };
  }, [location]);

  return (
    <MenuStyle>
      <div className="menu-mobile">
        <Link
          className={
            location === "/about/" || location === "/about" ? "active" : ""
          }
          to="/about"
        >
          About
        </Link>
        <Link className={location === "/" ? "active" : ""} to="/">
          Contact
        </Link>
        <Link
          className={
            location === "/projects/" || location === "/projects"
              ? "active"
              : ""
          }
          to="/projects"
        >
          Projects
        </Link>
      </div>
      <SvgComponentTrace1 pathRef={pathMenuRef} location={location} />
      {/* <SvgLineProjects pathRef={pathLineProjectRef} location={location} /> */}

      <SvgComputer pathRef={pathComputerRef} location={location} />

      <SvgTel pathRef={pathTelRef} location={location} />
      <SvgSmallMan pathRef={pathSmallManRef} location={location} />
      <SvgMiddleMan pathRef={pathMiddleManRef} location={location} />
      <SvgBigMan pathRef={pathBigManRef} location={location} />

      {/* <SvgArrow pathRef={pathArrowRef} location={location} /> */}
      <SvgTel pathRef={pathTelRef} location={location} />
    </MenuStyle>
  );
};
export default Menu;
